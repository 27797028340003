//
//
//
//
//
//
//
//
//

export default {
  name: 'SocialMedia',
  computed: {
    socialMedia() {
      //hide whatsapp from SocialMedia components,because it's not used.
      let socialMedia = this.$store.state.socialMedia
      return socialMedia.filter(item => item.title !== 'whatsapp')
    }
  },
}
